import { PocketTransfer } from "@components/resolvers/pocket.resolvers";
import axios from "axios";
import { BaseService } from "./base.service";
import { BaseResponse, BaseResponseExternal } from "./dtos/base.dto";
import {
  BillsResponse,
  DaftarTagihanResponse,
  DaftarZiswafResponse,
  MethodsResponse,
  PaymentPocketRequest,
  paymentZiswafWithPocket,
  PocketHistoryResponse,
  PocketResponse,
  VirtualAccountReportResponse,
  VirtualAccountRequest,
  VirtualAccountRequestZiswaf,
} from "./dtos/finance.dto";

interface FinanceServiceInterface {
  queries: {
    GET_FINANCE_BILLS: string;
    GET_FINANCE_ONE_BILL: string;
    GET_FINANCE_POCKET: string;
    GET_FINANCE_METHODS: string;
    GET_FINANCE_BILLS_PAID_OF: string;
    GET_FINANCE_CHECKING_ACCOUNT: string;
    GET_FINANCE_POCKET_HISTORY: string;
    GET_FINANCE_VIRTUAL_ACCOUNT_REPORT: string;
    GET_TAGIHAN: string;
    GET_FINANCE_ZISWAF: string;
  };
  bills(payload: {
    nis: string;
    limit: "10" | "20" | "50";
    periode: string;
  }): Promise<BillsResponse>;
  oneBill(payload: {
    nis: string;
    limit: "10" | "20" | "50";
    periode: string;
  }): Promise<BillsResponse>;
  billsPaidOf(): Promise<BillsResponse>;
  checkingAccount(payload: {
    range: "WEEK" | "MONTH";
    type: "ALL" | "IN" | "OUT";
  }): Promise<PocketHistoryResponse>;
  pocket(): Promise<PocketResponse>;
  pocketTransfer(payload: PocketTransfer): Promise<BaseResponse<number>>;
  pocketHistory(
    pocket: string,
    payload: {
      range: "WEEK" | "MONTH";
      type: "ALL" | "IN" | "OUT";
    }
  ): Promise<PocketHistoryResponse>;
  virtualAccountReport(payload: {
    range: "10" | "20" | "50";
    status: "ALL" | "PENDING" | "COMPLETED" | "EXPIRED" | "ONHOLD" | "REJECTED";
  }): Promise<VirtualAccountReportResponse>;
  virtualAccountCancel(id: string): Promise<BaseResponse>;
  virtualAccountRequest(payload: VirtualAccountRequest): Promise<BaseResponse>;
  virtualAccountConfirm(id: string): Promise<BaseResponse>;
  virtualAccountCheckStatus(payload: { trxID: string }): Promise<BaseResponse>;
  paymentWithPocket(payload: PaymentPocketRequest): Promise<BaseResponse>;
  methods(): Promise<MethodsResponse>;
  daftarTagihan(payload: { nis: string }): Promise<DaftarTagihanResponse>;
  ziswafList(): Promise<DaftarZiswafResponse>;
  virtualAccountRequestZiswaf(
    payload: VirtualAccountRequestZiswaf
  ): Promise<BaseResponse>;
  paymentZiswafWithPocket(
    payload: paymentZiswafWithPocket
  ): Promise<BaseResponse>;
}
export const FinanceService: FinanceServiceInterface = {
  queries: {
    GET_FINANCE_BILLS: "GET_FINANCE_BILLS",
    GET_FINANCE_ONE_BILL: "GET_FINANCE_ONE_BILL",
    GET_FINANCE_BILLS_PAID_OF: "GET_FINANCE_BILLS_PAID_OF",
    GET_FINANCE_CHECKING_ACCOUNT: "GET_FINANCE_CHECKING_ACCOUNT",
    GET_FINANCE_POCKET: "GET_FINANCE_POCKET",
    GET_FINANCE_METHODS: "GET_FINANCE_METHODS",
    GET_FINANCE_POCKET_HISTORY: "GET_FINANCE_POCKET_HISTORY",
    GET_FINANCE_VIRTUAL_ACCOUNT_REPORT: "GET_FINANCE_VIRTUAL_ACCOUNT_REPORT",
    GET_TAGIHAN: "GET_TAGIHAN",
    GET_FINANCE_ZISWAF: "GET_FINANCE_ZISWAF",
  },
  bills: async (payload: {
    nis: string;
    limit: "10" | "20" | "50";
    periode: string;
  }) => {
    try {
      // const request = await BaseService.request().get<BillsResponse>(
      //   "/finance/bills",
      //   { params: { limit } }
      // );
      // return BaseService.response(request);
      const request = await axios.get<BillsResponse>(
        `https://almatuq.aplikasi-pesantren.id/api/keuangan/bills`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );

      return {
        success: request.data.success,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  oneBill: async (payload: {
    nis: string;
    limit: "10" | "20" | "50";
    periode: string;
  }) => {
    try {
      const request = await axios.get<BillsResponse>(
        `https://almatuq.aplikasi-pesantren.id/api/keuangan/one-bill`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );

      return {
        success: request.data.success,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  daftarTagihan: async (payload: { nis: string }) => {
    try {
      const request = await axios.get(
        `https://almatuq.aplikasi-pesantren.id/api/tagihan/daftar-tagihan`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );

      return {
        success: request.data.success,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  billsPaidOf: async () => {
    try {
      const request = await BaseService.request().get<BillsResponse>(
        "/finance/bills-paid-of"
      );
      // const response = BaseService.response(request);
      // if (response.data) {
      //   response.data.sort((a: BillsResponse, b: BillsResponse) =>
      //     (a as any).nourut.localeCompare((b as any).nourut)
      //   );
      // }
      // return response;
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  ziswafList: async () => {
    try {
      const request = await axios.get(
        `https://almatuq.aplikasi-pesantren.id/api/tagihan/daftar-ziswaf`,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );

      return {
        success: request.data.success,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  checkingAccount: async (payload: {
    range: "WEEK" | "MONTH";
    type: "ALL" | "IN" | "OUT";
  }) => {
    try {
      const request = await BaseService.request().get<PocketHistoryResponse>(
        "/finance/checking-account",
        { params: payload }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  pocket: async () => {
    try {
      const request = await BaseService.request().get<BillsResponse>(
        "/finance/pocket"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  pocketTransfer: async (payload: PocketTransfer) => {
    try {
      const request = await BaseService.request().post<BaseResponse<number>>(
        `/finance/pocket/transfer`,
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  pocketHistory: async (
    pocket: string,
    payload: {
      range: "WEEK" | "MONTH";
      type: "ALL" | "IN" | "OUT";
    }
  ) => {
    try {
      const request = await BaseService.request().get<BillsResponse>(
        `/finance/pocket/${pocket}/history`,
        { params: payload }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  virtualAccountReport: async (payload: {
    range: "10" | "20" | "50";
    status: "ALL" | "PENDING" | "COMPLETED" | "EXPIRED";
  }) => {
    try {
      const request = await BaseService.request().get<BillsResponse>(
        `/finance/virtual-accounts-report`,
        { params: payload }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  virtualAccountCancel: async (id: string) => {
    try {
      const request = await BaseService.request().get<BillsResponse>(
        `/finance/virtual-accounts-cancel/${id}`
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  virtualAccountConfirm: async (id: string) => {
    try {
      const request = await axios.get(
        `https://almatuq.aplikasi-pesantren.id/api/tagihan/konfirmasi-transfer/${id}`,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  virtualAccountCheckStatus: async (payload: { trxID: string }) => {
    try {
      const request = await axios.get<BaseResponseExternal>(
        `https://almatuq.aplikasi-pesantren.id/api/tagihan/check-status`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  virtualAccountRequest: async (payload: VirtualAccountRequest) => {
    try {
      const request = await axios.post<
        BaseResponseExternal<{
          trxAmount: number;
          nis: string;
          customerName: string;
          virtualAccount: string;
          datetimeExpired: Date;
          trxID: string;
          jenis: string;
          requestPayment: string;
        }>
      >(
        "https://almatuq.aplikasi-pesantren.id/api/tagihan/submit_va_tagihan",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  virtualAccountRequestZiswaf: async (payload: VirtualAccountRequestZiswaf) => {
    try {
      const request = await axios.post<BaseResponseExternal>(
        "https://almatuq.aplikasi-pesantren.id/api/tagihan/submit_va_ziswaf",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  paymentZiswafWithPocket: async (payload: paymentZiswafWithPocket) => {
    try {
      const request = await axios.post<BaseResponseExternal>(
        "https://almatuq.aplikasi-pesantren.id/api/tagihan/submit_ziswaf",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  paymentWithPocket: async (payload: PaymentPocketRequest) => {
    try {
      const request = await axios.post<BaseResponseExternal>(
        "https://almatuq.aplikasi-pesantren.id/api/tagihan/submit_bayar_tagihan",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: null,
      };
    } catch (error: any) {
      throw new Error("Terjadi Kesalahan Server!");
    }
  },
  methods: async () => {
    try {
      const request = await axios.get<BaseResponseExternal>(
        "https://almatuq.aplikasi-pesantren.id/api/pondok/list_rekening",
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return BaseService.response(request);
    } catch (error: any) {
      throw new Error("Terjadi Kesalahan Server!");
    }
  },
};
