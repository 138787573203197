import { PopupSuccess } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import {
  PermitRequest,
  permitRequestSchema,
} from "@components/resolvers/permit.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { PermitService } from "@services/permit.service";
import { SchoolService } from "@services/school.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
import id from "date-fns/locale/id";
import lodash from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
registerLocale("id", id);
setDefaultLocale("id");

export const PermitFormPage = () => {
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const { pushNotification } = useNotificationStore((state) => state);

  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [listOpenDate, setListOpenDate] = useState<Date[]>([]);
  const [disabledOption, setDisabledOption] = useState<boolean>(false);

  const { data: settings } = useQuery({
    queryKey: [PermitService.queries.GET_PERMIT_SETTING],
    queryFn: () => PermitService.setting(),
  });

  const { data: dataNis } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });
  const nis = dataNis?.data?.nis ?? "";

  const { data: dataMenginap } = useQuery({
    queryKey: [PermitService.queries.GET_DATA_MENGINAP, id],
    queryFn: () => PermitService.dataMenginap({ id: nis }),
  });

  const { data: dataGender } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_GENDER, nis],
    queryFn: () => SchoolService.studentGender({ nis }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PermitRequest>({
    resolver: yupResolver<PermitRequest>(permitRequestSchema),
  });

  const { mutate, isPending } = useMutation({
    mutationFn: PermitService.request,
    onSuccess: (response) => {
      if (response.success) {
        setPopupSuccess(true);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  useEffect(() => {
    dataMenginap?.data.length > 0
      ? setDisabledOption(true)
      : setDisabledOption(false);
  }, [dataMenginap, setDisabledOption]);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Request Izin",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Request Izin" });
  }, [setFooter, setHeader, setAppBar]);

  const onChangeMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const time = e.target.options[e.target.selectedIndex]?.dataset?.time ?? "";
    setListOpenDate(
      (settings?.data ?? [])
        .filter((item) => {
          const gender = dataGender?.data?.gender;
          const genderMap =
            gender?.toLowerCase() === "pria"
              ? "PUTRA"
              : gender?.toLowerCase() === "wanita"
              ? "PUTRI"
              : "SEMUA";
          return item.gender === genderMap || item.gender === "SEMUA";
        })
        .filter((item) => item.name === time)
        .map((item) => moment(item.date).toDate())
    );
  };

  const onChangeDate = (date: Date | null) => {
    if (date !== null) {
      setValue("dateBack", moment(date).format("YYYY-MM-DD HH:mm:ss"));
      setValue("datePermit", moment(date).format("YYYY-MM-DD HH:mm:ss"));
      setStartDate(date);
    }
  };

  return (
    <>
      <SpinerPage show={isPending} />

      <div className="card card-style">
        <div className="content mb-4 !tw-mt-6">
          <form
            autoComplete="off"
            onSubmit={handleSubmit((data) => mutate(data))}
          >
            <div className="input-style has-borders input-style-always-active no-icon mb-3">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Izin
              </label>
              <select
                className="bg-theme"
                {...register("mode")}
                onChange={onChangeMode}
              >
                <option value="">Pilih Izin</option>
                {lodash
                  .uniqBy(
                    (settings?.data ?? []).filter((item) => {
                      const gender = dataGender?.data?.gender;
                      const genderMap =
                        gender?.toLowerCase() === "pria"
                          ? "PUTRA"
                          : gender?.toLowerCase() === "wanita"
                          ? "PUTRI"
                          : "SEMUA";
                      return item.gender === genderMap;
                    }),
                    "name"
                  )
                  .map((item, idx) => (
                    <option
                      key={idx}
                      value={item.type}
                      data-time={item.name}
                      disabled={
                        item.type.toLowerCase() === "menginap"
                          ? disabledOption
                          : false
                      }
                    >
                      {item.name}{" "}
                      {item.type.toLowerCase() === "menginap"
                        ? `(kuota menginap: ${disabledOption ? 0 : 1})`
                        : ""}
                    </option>
                  ))}
              </select>
              {/* <input type="hidden" {...register("time")} /> */}
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4 mt-3">
              <DatePicker
                selected={startDate}
                minDate={new Date()}
                maxDate={moment().add(3, "months").toDate()}
                onChange={onChangeDate}
                className="form-control dark:form-control"
                dateFormat="dd MMMM yyyy"
                includeDates={listOpenDate}
                calendarStartDay={0}
              />
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
              <label
                htmlFor="permit-date"
                className="color-highlight font-400 font-13"
              >
                Tanggal Izin
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                placeholder="Nama Penjemput"
                {...register("pickup")}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Nama Penjemput
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              {/* <input
                type="text"
                className="form-control bg-theme"
                placeholder="Status Penjemput"
                {...register("pickupStatus")}
              /> */}
              <label
                htmlFor="form5"
                className="color-highlight font-400 font-13"
              >
                Status Penjemput
              </label>
              <select {...register("pickupStatus")} className="bg-theme">
                <option value="default">Pilih Status</option>
                <option value="Orang Tua Kandung">Orang Tua Kandung</option>
                <option value="Paman/Kakek/Bibi">Paman/Kakek/Bibi/Nenek</option>
                <option value="Kakak Laki-Laki/Perempuan Kandung">
                  Kakak Laki-Laki/Perempuan Kandung
                </option>
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <textarea
                className="form-control bg-theme"
                placeholder="catatan ..."
                {...register("notes")}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Catatan
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 tw-w-full tw-h-11"
            >
              Request Izin
            </button>
          </form>
        </div>
      </div>
      <PopupSuccess
        show={popupSuccess}
        title="Berhasil!"
        message="Berhasil membuat permintaan izin"
        textButton="Kembali"
        onConfirm={() => navigate(-1)}
      />
    </>
  );
};
