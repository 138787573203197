import { PermitRequest } from "@components/resolvers/permit.resolvers";
import { BaseService } from "./base.service";
import { BaseResponse, BaseResponseExternal } from "./dtos/base.dto";
import { PermitResponse, PermitSettingResponse } from "./dtos/permit.dto";
import axios from "axios";

interface PermitServiceInterface {
  queries: {
    GET_PERMIT_DATA: string;
    GET_PERMIT_SETTING: string;
    GET_DATA_MENGINAP: string;
  };
  request(payload: PermitRequest): Promise<BaseResponse<boolean>>;
  data(limit: string): Promise<PermitResponse>;
  setting(): Promise<PermitSettingResponse>;
  permitCancel(payload: { id: string }): Promise<BaseResponse>;
  dataMenginap(payload: { id: string }): Promise<BaseResponse>;
}
export const PermitService: PermitServiceInterface = {
  queries: {
    GET_PERMIT_DATA: "GET_PERMIT_DATA",
    GET_PERMIT_SETTING: "GET_PERMIT_SETTING",
    GET_DATA_MENGINAP: "GET_DATA_MENGINAP",
  },
  request: async (payload: PermitRequest) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/permit/request",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  data: async (limit: string) => {
    try {
      const request = await BaseService.request().get<PermitResponse>(
        "/permit/data",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  setting: async () => {
    try {
      const request = await BaseService.request().get<PermitSettingResponse>(
        "/permit/setting"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },

  permitCancel: async (payload: { id: string }) => {
    try {
      const request = await axios.get<BaseResponseExternal>(
        `https://almatuq.aplikasi-pesantren.id/api/perizinan/cancel`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  dataMenginap: async (payload: { id: string }) => {
    try {
      const request = await axios.get<BaseResponseExternal>(
        `https://almatuq.aplikasi-pesantren.id/api/perizinan/get-data-menginap`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
};
