import { PopupConfirm, PopupSuccess } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import { PermitService } from "@services/permit.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const PermitDataDetailPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);
  const [popupCancel, setPopupCancel] = useState<boolean>(false);
  const [permitID, setPermitID] = useState<string>("");
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [popupTextConfirm, setPopupTextConfirm] = useState<string>("");

  const { mutate, isPending } = useMutation({
    mutationFn: PermitService.permitCancel,
    onSuccess: (response) => {
      if (response.success) {
        setPermitID("");
        setPopupCancel(false);
        setPopupSuccess(true);
        // pushNotification({
        //   type: "success",
        //   message: "Permintaan Izin berhasil dibatalkan",
        // });
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });
  const onOpenPopupCancel = (id: string) => {
    setPermitID(id);
    setPopupTitle("Batalkan");
    setPopupMessage("Jika dibatalkan maka harus membuat ulang izin baru");
    setPopupTextConfirm("Ya, Batalkan");
    setPopupCancel(true);
  };

  const handleConfirm = (title: string) => {
    if (title === "Batalkan") {
      setPopupCancel(false);
      mutate({ id: permitID });
    }
  };

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Izin",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Izin", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <>
      <SpinerPage show={isPending} />
      <div className="card card-style">
        <div className="content d-flex flex-column mb-0">
          <div className="color-highlight">Status Izin</div>
          <div className="color-theme mb-3">
            {state.acc === "Y"
              ? "Diizinkan"
              : state.acc === "N"
              ? "Tidak Diizinkan"
              : state.acc === "BELUM"
              ? "Menunggu Konfirmasi"
              : "-"}
          </div>
          <div className="color-highlight">Tanggal Izin</div>
          <div className="color-theme mb-3">
            {moment(state.datePermit ?? Date.now()).format(
              "dddd, DD MMMM YYYY"
            )}
          </div>
          {/* <div className="color-highlight">Tanggal Kembali</div>
        <div className="color-theme mb-3">
          {moment(state.dateBack ?? Date.now()).format(
            "dddd, DD MMMM YYYY, HH:mm"
          )}
        </div> */}
          <div className="color-highlight">Jenis Izin</div>
          <div className="color-theme mb-3">{state.mode ?? "-"}</div>
          {/* <div className="color-highlight">Waktu Izin</div>
        <div className="color-theme mb-3">{state.time ?? '-'}</div> */}
          <div className="color-highlight">Catatan Admin</div>
          <div className="color-theme mb-3">
            {state.adminNotes === "" ? "-" : state.adminNotes}
          </div>
          <div className="color-highlight">Nama Penjemput</div>
          <div className="color-theme mb-3">{state.pickup ?? "-"}</div>
          <div className="color-highlight">Status Penjemput</div>
          <div className="color-theme mb-3">{state.pickupStatus ?? "-"}</div>
          <div className="color-highlight">Catatan</div>
          <div className="color-theme mb-3">{state.notes ?? "-"}</div>
        </div>
      </div>
      {state.acc === "BELUM" && (
        <div className="card card-style !tw-mb-4">
          <div className="content">
            <>
              <button
                className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-red-dark tw-grow tw-w-full tw-h-11"
                onClick={() => onOpenPopupCancel(state?.id ?? "")}
              >
                Batalkan Izin
              </button>
            </>
          </div>
        </div>
      )}
      <PopupConfirm
        height={200}
        show={popupCancel}
        title={popupTitle}
        message={popupMessage}
        textConfirm={popupTextConfirm}
        textCancel="Kembali"
        onConfirm={() => handleConfirm(popupTitle)}
        onCancel={() => setPopupCancel(false)}
      />

      <PopupSuccess
        show={popupSuccess}
        title="Berhasil!"
        message="Permintaan Izin berhasil dibatalkan"
        textButton="Kembali"
        onConfirm={() => navigate(-1)}
      />
    </>
  );
};
